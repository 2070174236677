
import { loader, updateVar } from "./popups.js";

export default () => {
    $('.js-newsletter-sign-up:not(.js-newsletter-sign-up-done)').each(function() {
        var wrapper = $(this);
        var allow = true;
        var form = wrapper.find('form');
        // submit
        form.off('submit').on('submit', function() {
            if (!allow || !form.valid()) return false;
            allow = false;

            var title = 'Error';
            var message = '';

            // show loader
            loader();

            // ajax
            $.ajax({
                method: 'POST',
                url: form.attr('action'),
                data: form.serialize(),
                success: function(response) {
                    // message
                    if (response.Valid) {
                        title = window.popupData.newsletter.success.title;
                        message = (response.Message || window.popupData.newsletter.success.message);
                        // clear fields
                        form.trigger('reset');
                    }
                    else {
                        message = (response.Message || 'Sorry, there was an error. Please try again.');
                    }
                },
                error: function(e) {
                    // message
                    message = 'Sorry, there was an error (' + e.statusText.toLowerCase() + ').';
                },
                complete: function() {
                    // allow
                    allow = true;
                    // hide loader
                    loader(true);
                    updateVar('allow', true)
                    updateVar('fadeClick', true)

                    // show message
                    confirm(title, message, '', true);
                }
            });

            return false;
        });

        // done
        wrapper.addClass('js-newsletter-sign-up-done');
    });
}
